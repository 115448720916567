<template>
  <div>
    <Modal class="pop-guide" footer-hide v-model="showGuide">
      <!--      <div class="top">-->
      <!--        MaxTrade Instruction-->
      <!--      </div>-->
      <div class="content">
        <div class="tabs">
          <div class="tab">
            <div
              :class="[
                'tab-word',
                'search-button',
                { 'active-tab-word': current === 'buy' }
              ]"
              @click="changeCurrent('buy')"
            >
              I want to buy
            </div>
            <div class="tab-line" v-if="current === 'buy'"></div>
          </div>
          <div class="tab">
            <div
              :class="[
                'tab-word',
                'search-button',
                { 'active-tab-word': current === 'sell' }
              ]"
              @click="changeCurrent('sell')"
            >
              I want to sell
            </div>
            <div class="tab-line" v-if="current === 'sell'"></div>
          </div>
        </div>

        <div class="tabs-line"></div>

        <div class="guide-content">
          <div class="buy" v-if="current === 'buy'">
            <div class="steps-content">
              <div class="step">
                <div class="left">
                  <div class="item-number">1</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Post inquiries for hard-to-find ingredients.
                  </div>
                </div>
              </div>
              <div class="step-line"></div>
              <div class="step">
                <div class="left">
                  <div class="item-number">2</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    View existing deals on ingredients and secure special prices.
                  </div>
                </div>
              </div>
              <div class="step">
                <div class="left">
                  <div class="item-number">3</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Select from incoming offers and connect directly online.
                  </div>
                </div>
              </div>
              <div class="step">
                <div class="left">
                  <div class="item-number">4</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Receive Email alerts for incoming quotations.
                  </div>
                </div>
              </div>
              <div class="step">
                <div class="left">
                  <div class="item-number">5</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Review history under "My inquiries" and compare offers.
                  </div>
                </div>
              </div>
              <div class="step">
                <div class="left">
                  <div class="item-number">6</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Subscribe to newsletters for ingredients deals and limited-time offers.
                  </div>
                </div>
              </div>
              <div class="step">
                <div class="left">
                  <div class="item-number">7</div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Access full contact information directly as a premium member.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sell" v-else>
            <div class="steps-content">
              <div class="step">
                <div class="left">
                  <div class="item-number">1</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Post ingredient offers to manage inventory actively.
                  </div>
                </div>
              </div>
              <div class="step-line"></div>
              <div class="step">
                <div class="left">
                  <div class="item-number">2</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    View existing sourcing inquiries and submit your quotation.
                  </div>
                </div>
              </div>
              <div class="step">
                <div class="left">
                  <div class="item-number">3</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Select from incoming offers and connect directly online.
                  </div>
                </div>
              </div>
              <div class="step">
                <div class="left">
                  <div class="item-number">4</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Receive Email notifications regarding the status of your offers.
                  </div>
                </div>
              </div>
              <div class="step">
                <div class="left">
                  <div class="item-number">5</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Review history under "my quotations" and manage sales.
                  </div>
                </div>
              </div>
              <div class="step">
                <div class="left">
                  <div class="item-number">6</div>
                  <div class="step-line"></div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Subscribe to newsletters for the latest demanded ingredients in the market.
                  </div>
                </div>
              </div>
              <div class="step">
                <div class="left">
                  <div class="item-number">7</div>
                </div>
                <div class="right">
                  <div class="step-title"></div>
                  <div class="step-body">
                    Access full contact information directly as a premium member.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="close" @click="cancel">Close</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["inquiry"],
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      inquiryForm: { price: "", note: "", id: "" },
      showGuide: false,
      loading: false,
      formData: new FormData(),
      current: "buy"
      // inquriy: {}
    };
  },
  mounted() {},
  filters: {},
  methods: {
    changeCurrent(current) {
      this.current = current;
    },
    getFile(event, input_file_name) {
      this.formData.append(input_file_name, event.target.files[0]);
    },
    cancel() {
      this.showGuide = false;
    }
  }
};
</script>
<style lang="less" scoped>
.pop-guide {
  .top {
    color: #23262f;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .template {
    padding: 20px 0;
    span {
      font-weight: 600;
      a {
        color: #f57f45;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: right;
    .cancel {
      color: #989696;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .upload {
      background: #f57f45;
      color: #fff;
      height: 35px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .uploading {
      background: #ffb894;
    }
    .loading-button {
      opacity: 0.8;
      img {
        width: 18px;
      }
    }
  }
}
.content {
  .tabs {
    display: flex;
    justify-content: space-between;
    .tab {
      width: 50%;
      text-align: center;
      .tab-word {
        font-size: 17px;
        font-weight: 600;
      }
      .active-tab-word {
        font-size: 18px;
        font-weight: 600;
        color: #f57f45;
      }
      .tab-line {
        height: 2px;
        background: #f57f45;
        position: relative;
        top: 16px;
      }
    }
  }
  .tabs-line {
    width: 100%;
    height: 1px;
    background: #d9d9d9;
    margin: 15px auto 15px auto;
  }
}
.steps-content {
  .step {
    /*width: 860px;*/
    margin: 0 auto;
    display: flex;
    .left {
      .step-line {
        width: 1px;
        height: 10px;
        background: rgba(245, 124, 0, 0.3);
        margin: 10px auto;
      }
      .item-number {
        width: 25px;
        height: 25px;
        background: rgba(245, 124, 0, 0.3);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
        color: #f57f45;
      }
    }
    .right {
      .step-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #4f4f4f;
        display: flex;
        justify-content: left;
        /*margin-bottom: 10px;*/
      }
      .step-body {
        /*font-weight: 600;*/
        font-size: 15px;
        text-align: left;
        color: #4f4f4f;
        margin-top: 2px;
      }
    }
  }
}
.close {
  margin: 20px auto 0 auto;
  width: 150px;
  background: #f57f45;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border-radius: 10px;
  color: #fff;
  height: 35px;
}
</style>

<style lang="less">
.pop-guide {
  .ivu-modal {
    width: 100% !important;
    max-width: 620px;
    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
